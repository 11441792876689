<template>
  <div>
    <van-nav-bar title="教程详情" left-arrow  @click-left="$router.go(-1)"/>
    <div class="content-padding news-detail">
      <h1>{{ news.title }}</h1>
      <span class="date">发布时间：{{ news.postedAt }}</span>
      <van-image :src="news.image"></van-image>
      <div class="news-content" v-html="news.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'course-detail',
  data () {
    return {
      news: {},
      newsId: this.$route.params.id
    }
  },
  created () {
    this.getNews()
  },
  methods: {
    getNews () {
      this.$http.get('https://huami-com-admin.huami.com/v1/amazfit-courses/' + this.newsId).then((res) => {
        this.news = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.news-detail {
  h1 {
    font-size: 16px;
    line-height: 100%;
  }

  .date {
    display: inline-block;
    margin: 5px 0;
  }

  .news-content {
  }
}
</style>
